import { Component, Mixins, Prop } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ScreenText } from '@/lang/ScreenText';
import AttendanceDisplayColumns from "./../attendanceDisplayColumns/attendanceDisplayColumns.vue";
import attendanceStore from '@/store/modules/attendanceStore';

@Component({  
    components: {  
        "display-columns-dropdown": AttendanceDisplayColumns,
    }
})
export default class LeftTableComponent extends Mixins(DeviceWidthCheckerMixin) {

    public tableHeaders = APP_CONST.ATTENDANCE_HEADER_DESKTOP.FIXED;
    private objScreenText: ScreenText = new ScreenText();
    public drill:any=false;
    selectAll: boolean = false;
    roleId: number = 0;
    openDropDown: boolean = false;
    public dynamicHeight: any = ''
    public showSettings: boolean = false;
    private ascOrder:string = 'asc';
    private descOrder:string = 'desc';
    public openHeader: any = {};
    public tabKey:boolean=false
    public enterKey:boolean=false;

    // @Prop()
    // showSettings!:boolean
    
    @Prop()
    filteredColumns!:any

    @Prop()
    scholarAttedanceList!: any
    
    @Prop()
    displayTable!:boolean

    @Prop()
    tabSelected!: string

    @Prop()
    tabSelectedValue!: string

    @Prop()
    currentSortOrder!: any

    @Prop()
    firstNameSorted!: boolean
    
    @Prop()
    lastNameSorted!: boolean

    @Prop()
    searchValue!:any
    
    @Prop()
    currentSortedColumn!:any
    
    @Prop()
    searchField!:string

    @Prop()
    weekDay!:string

    @Prop({ default: false })
    enhanced!:boolean;

    get attendancePayload(){
        return attendanceStore.attendanceAPIPayload
    }

    sortIcon(header: string){
        if(this.attendancePayload.SearchField == header && this.attendancePayload.sortField == header){
            return this.attendancePayload.sortDir == 1 ? 'sort-a-to-z-white' : 'sort-z-to-a-white'
        }
        else{
            return this.attendancePayload.sortDir == 1 ? 'sort-a-to-z-green' : 'sort-z-to-a-green'
        }
    }

    mounted(){
        let drilldrown = APP_UTILITIES.getCookie("super_user_drilldown");
        this.drill=drilldrown;
        const {roleId, programId, siteId, accountId} = APP_UTILITIES.coreids();
        this.roleId = roleId;
    }
    

    openDetailsSidePanel(record:any){
        this.$emit('openDetailsSidePanel', record)
    }

    filterColumns(value:any){
        this.$emit('filterColumns', value);
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    setHeight(value: any){
        this.dynamicHeight = value;
        this.$emit('setHeight', this.dynamicHeight)
    }

    changeDisplayDayAttendanceColumn(diplayValue: boolean){
        this.$emit('changeDisplayDayAttendanceColumn', diplayValue)
    }

    changeDisplayWeekAttendanceColumn(display: boolean, key: string){
        this.$emit('changeDisplayWeekAttendanceColumn', display, key)
    }

    changeAllAttendanceColumns(display: boolean){
        this.$emit('changeAllAttendanceColumns', display);
    }

    sortHeaders(field: string, dayItem?:any){
        this.$emit('sortHeaders', field, dayItem)
    }

    openSortingDropdown(header: any, $event: any) {
    let elem = <HTMLDivElement>document.getElementById(header.value);
    let pos = elem.getBoundingClientRect();
    let x = pos.left + APP_CONST.FOURTEEN;
    let y = pos.top + APP_CONST.TWENTY_FIVE;
    this.$emit("openSortingDropdown", header, { x, y });
    }

    handleSortingArrows(header: any) {
        this.tableHeaders.forEach((mainHeader: any) => {
          if (mainHeader.value === header.value) {
            mainHeader.isOpen = !mainHeader.isOpen;
          }
        })
      }

    prepareScholarAttendanceData(searchSortData:any){
                this.$emit('prepareScholarAttendanceData',searchSortData)
            }

    openGearDropdown(event:any){
       if(event.keyCode == 40 || event.keyCode == 38 || event.keyCode == 32 || event.keyCode == 13){
         this.showSettings = true;
         event.preventDefault();
      }
      let id:any = document.getElementById('display-all');
      if(id){
         setTimeout(()=>{
            id.focus();
         },20)
      }
    }

    getFilterDataFromStorage(){
        this.$emit('getFilterDataFromStorage');
    }

    getHeadersFromStorage(){
        this.$emit('getHeadersFromStorage')
    }

    closeGearIcon(){
        this.showSettings = false;
        let id:any = document.getElementById('attendace-seeting-icon');
        id && id.focus();
    }

    tab(val:boolean){
        this.tabKey = true;
        this.showSettings = false;
    }
    
    enter(val:boolean){
        this.enterKey = true;
        this.showSettings = false;
    }
}